import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { RefresherEventDetail } from "@ionic/core";
import { IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { LinearProgress, Stack } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { AttendanceScoreRecentActivity } from "@src/appV2/AttendanceScore/attendanceScoreRecentActivity";
import { LatenessDeductionWarningAlert } from "@src/appV2/AttendanceScore/components/LatenessDeductionWarningAlert";
import { useClipboardScoreRollout } from "@src/appV2/AttendanceScore/hooks/featureFlags";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { SupportArticleLinks } from "@src/appV2/support/constants";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants";

import { AttendanceScorePolicyDetailCard } from "./attendanceScorePolicyDetailCard";
import {
  ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING,
  LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING,
} from "./constants";
import { LearnMoreCard } from "./learnMoreCard";
import { MyAttendanceScoreContainer } from "./myAttendanceScoreContainer";
import { TabRouterPath } from "../routing/constant/tabRoute";

export function AttendanceScoreRatingPage() {
  const worker = useDefinedWorker();

  const attendanceScorePolicyLinks = useCbhFlag(CbhFeatureFlag.ATTENDANCE_SCORE_POLICY_LINKS, {
    defaultValue: {},
  });

  const {
    isLoading,
    data: {
      score = 0,
      clipboardScore = 0,
      restrictionCount,
      policy,
      accountStatus,
      latenessPointsDeductedInLast30Days,
    } = {},
    isSuccess,
    refetch,
  } = useGetAttendanceScoreProfile(worker?.userId);
  const isClipboardScoreEnabled = useClipboardScoreRollout();

  const showLatenessDeductionWarningAlert =
    isSuccess &&
    accountStatus?.status === AgentStage.ENROLLED &&
    (score ?? ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING) <
      ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING &&
    (latenessPointsDeductedInLast30Days ?? 0) > LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING;

  useLogEffect(
    USER_EVENTS.SHOW_TIMELINESS_DEDUCTION_WARNING,
    {
      score,
      latenessPointsDeductedInLast30Days,
      restrictionCount,
    },
    { enabled: showLatenessDeductionWarningAlert }
  );

  const statusPolicyLink = {
    label:
      attendanceScorePolicyLinks?.understandingAccountStatus?.label ??
      "Understanding Your Account Status",
    url:
      attendanceScorePolicyLinks?.understandingAccountStatus?.url ??
      SupportArticleLinks.ACCOUNT_STATUS,
  };

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Attendance Score"
            leftCta={<BackButtonLink defaultBackTo={TabRouterPath.RATINGS} />}
          />
        }
        containerVariant="without-margin"
        containerMaxWidth="md"
        onRefresh={refetch}
      >
        <IonRefresher
          slot="fixed"
          onIonRefresh={(refreshEvent?: CustomEvent<RefresherEventDetail>) => {
            refetch();
            refreshEvent?.detail.complete();
          }}
        >
          <IonRefresherContent />
        </IonRefresher>

        {isLoading && (
          <LinearProgress sx={{ height: "4px" }} data-testid="attendance-score-progressbar" />
        )}

        {isSuccess && isDefined(accountStatus) && (
          <>
            <MyAttendanceScoreContainer
              score={isClipboardScoreEnabled ? clipboardScore : score}
              accountStatus={accountStatus}
              policyDetailUrl={
                attendanceScorePolicyLinks?.howAttendanceWorks?.url ||
                SupportArticleLinks.ATTENDANCE_SCORE
              }
              tmz={worker.tmz}
            />
            {isDefined(accountStatus?.status) &&
              accountStatus.status === AgentStage.ENROLLED &&
              score < ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING &&
              (latenessPointsDeductedInLast30Days ?? 0) >
                LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING && (
                <Stack sx={{ padding: "16px", paddingBottom: "0px" }}>
                  <LatenessDeductionWarningAlert
                    latenessPointsDeductedInLast30Days={latenessPointsDeductedInLast30Days}
                  />
                </Stack>
              )}
            {policy && <AttendanceScorePolicyDetailCard policy={policy} />}
            <Stack sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
              <AttendanceScoreRecentActivity
                workerUserId={worker.userId}
                restrictionCount={restrictionCount}
                restrictionDuration={policy?.restriction?.[0]?.duration}
                finalRestriction={policy?.finalRestriction}
              />
            </Stack>
            <LearnMoreCard
              links={[
                {
                  label:
                    attendanceScorePolicyLinks?.howAttendanceWorks?.label ??
                    "How the Attendance Score Works",
                  url:
                    attendanceScorePolicyLinks?.howAttendanceWorks?.url ||
                    SupportArticleLinks.ATTENDANCE_SCORE,
                },
                statusPolicyLink,
                {
                  label:
                    attendanceScorePolicyLinks?.doNotReturnPolicy?.label ??
                    "Do Not Return (DNR) Policy",
                  url:
                    attendanceScorePolicyLinks?.doNotReturnPolicy?.url ||
                    SupportArticleLinks.DNR_REQUESTS_WORKER,
                },
              ]}
            />
          </>
        )}
      </PageWithHeader>
    </IonPage>
  );
}
